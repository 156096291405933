import * as React from 'react'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function App() {

  const [tabIndex, setTabIndex] = React.useState('2')
  const handleChange = (_, newValue) => {
    setTabIndex(newValue)
  }

  const [language, setLanguage] = React.useState(() => {
    const saved = localStorage.getItem("birdie.no-language")
    return saved || "no"
  })

  const languageChange = (newValue) => {
    localStorage.setItem("birdie.no-language", newValue)
    setLanguage(newValue)
  }

  const [playerHcp, setPlayerHcp] = React.useState(() => {
    const saved = localStorage.getItem("birdie.no-spiller-hcp")
    return saved || 12.7
  })

  const playerHcpChange = (newValue) => {
    localStorage.setItem("birdie.no-spiller-hcp", newValue)
    setPlayerHcp(newValue)
  }

  const [baneSlope, setBaneSlop] = React.useState(() => {
    const saved = localStorage.getItem("birdie.no-bane-slope")
    return saved || 135
  })
  const baneSlopeChange = (newValue) => {
    localStorage.setItem("birdie.no-bane-slope", newValue)
    setBaneSlop(newValue)
  }

  const [baneCourse, setBaneCourse] = React.useState(() => {
    const saved = localStorage.getItem("birdie.no-bane-course")
    return saved || 71.7
  })
  const baneCourseChange = (newValue) => {
    localStorage.setItem("birdie.no-bane-course", newValue)
    setBaneCourse(newValue)
  }

  const [holePars, setHolePars] = React.useState(() => {
    const saved = localStorage.getItem("birdie.no-hull-par")
    const initialValue = JSON.parse(saved)
    return initialValue || [4,5,4,4,3,5,3,4,4,4,4,3,4,5,4,4,3,5]
  })

  const setHolePar = (hole, newParValue) => {
    const holeParsCopy = [...holePars]
    holeParsCopy[hole] = newParValue
    localStorage.setItem("birdie.no-hull-par", JSON.stringify(holeParsCopy))
    setHolePars([...holeParsCopy])
  }

  const [holeHcps, setHoleHcps] = React.useState(() => {
    const saved = localStorage.getItem("birdie.no-hull-index")
    const initialValue = JSON.parse(saved)
    return initialValue || [6,10,12,16,14,2,18,4,8,3,9,17,7,1,13,11,15,5]
  })

  const setHoleHcp = (hole, newHcpValue) => {
    const holeHcpsCopy = [...holeHcps]
    holeHcpsCopy[hole] = newHcpValue
    localStorage.setItem("birdie.no-hull-index", JSON.stringify(holeHcpsCopy))
    setHoleHcps([...holeHcpsCopy])
  }

  const [holeStrokes, setHoleStrokes] = React.useState([0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0])

  const setHoleStroke = (hole, newStrokeValue) => {
    const holeStrokesCopy = [...holeStrokes]
    holeStrokesCopy[hole] = newStrokeValue
    setHoleStrokes([...holeStrokesCopy])
  }

  const totalExtraHcpStokes = () => Math.round(parseFloat(playerHcp) * parseFloat(baneSlope) / 113.0 + parseFloat(baneCourse) - holePars.reduce((partialSum, par) => partialSum + parseInt(par), 0))

  const extraHcpStokes = (hole) => {
    const totalExtraStrokes = totalExtraHcpStokes()
    const extraForAllHoles =
      totalExtraStrokes<18
      ? 0
      : totalExtraStrokes<36
        ? 1
        : totalExtraStrokes<54
          ? 2
          : 3

    const totalReminderExtraStrokes = totalExtraStrokes - (extraForAllHoles*18)
    const holeReminderExtraStroke = parseInt(holeHcps[hole]) <= totalReminderExtraStrokes ? 1 : 0 
    return extraForAllHoles + holeReminderExtraStroke
  }

  return (
    <div>
      <TabContext value={tabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={language === "eng" ? "Scorecard" : "Scorekort"} value="1" />
            <Tab label={language === "eng" ? "Front 9" : "Front 9"} value="2" />
            <Tab label={language === "eng" ? "Back 9" : "Back 9"} value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div class='padBottom'>{language === "eng" ? "Player Handicap" : "Spiller Handicap"}</div>
          <TextField 
            sx={{ width: 150 }} size="small" id="player-hcp" value={playerHcp}
            onChange={event => {playerHcpChange(event.target.value)}} />
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Course Rating" : "Bane Course Rating"}</div>
          <TextField sx={{ width: 200 }} size="small" id="bane-course-rating" value={baneCourse}
            onChange={event => {baneCourseChange(event.target.value)}} />
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Course Slope Rating" : "Bane Slope Rating"}</div>
          <TextField sx={{ width: 200 }} size="small" id="bane-slope-rating" value={baneSlope}
            onChange={event => {baneSlopeChange(event.target.value)}} />
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Course Hole Par - Front 9" : "Bane Hull Par - Første 9"}</div>
          {([0,1,2,3,4,5,6,7,8]).map(hole => (
            <TextField sx={{ width: 37 }} size="small" id={"bane-par-"+hole} value={holePars[hole]}
              onChange={event => {setHolePar(hole,event.target.value)}} />
          ))}
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Course Hole Par - Back 9" : "Bane Hull Par - Siste 9"}</div>
          {([9,10,11,12,13,14,15,16,17]).map(hole => (
            <TextField sx={{ width: 37 }} size="small" id={"bane-par-"+hole} value={holePars[hole]}
              onChange={event => {setHolePar(hole,event.target.value)}} />
          ))}
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Course Hole Handicap - Front 9" : "Bane Hull Index - Første 9"}</div>
          {([0,1,2,3,4,5,6,7,8]).map(hole => (
            <TextField sx={{ width: 46 }} size="small" id={"bane-hcp"+hole} value={holeHcps[hole]}
              onChange={event => {setHoleHcp(hole,event.target.value)}} />
          ))}
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Course Hole Handicap - Back 9" : "Bane Hull Index - Siste 9"}</div>
          {([9,10,11,12,13,14,15,16,17]).map(hole => (
            <TextField sx={{ width: 46 }} size="small" id={"bane-hcp"+hole} value={holeHcps[hole]}
              onChange={event => {setHoleHcp(hole,event.target.value)}} />
          ))}
          <div></div><br/>
          <div class='padBottom'>{language === "eng" ? "Language" : "Språk"}</div>
          <Select 
            id="language"
            size="small"
            value={language}
            onChange={event => {languageChange(event.target.value)}}>
            <MenuItem value={"no"}>{language === "eng" ? "Norwegian" : "Norsk"}</MenuItem>
            <MenuItem value={"eng"}>{language === "eng" ? "English" : "Engelsk"}</MenuItem>
          </Select>
        </TabPanel>
        <TabPanel value="2">
          <Table sx={{ minWidth: 100, maxWidth: 360 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell><b>{language === "eng" ? "Hole" : "Hull"}</b></TableCell>
                <TableCell><b>Par</b></TableCell>
                <TableCell><b>Hcp</b></TableCell>
                <TableCell><b>{language === "eng" ? "Strokes" : "Slag"}</b></TableCell>
                <TableCell><b>Score</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {([0,1,2,3,4,5,6,7,8]).map((hole) => (
                <TableRow
                  key={hole}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <TableCell><b>{hole+1}</b></TableCell>
                  <TableCell>{holePars[hole]}</TableCell>
                  <TableCell>{(extraHcpStokes(hole) > 0) ? ("+"+(extraHcpStokes(hole))) : ""}</TableCell>
                  <TableCell>
                    <TextField sx={{ width: 40 }} size="small" id={"hole-strokes"+hole} value={holeStrokes[hole] > 0 ? holeStrokes[hole] : ""}
                      onChange={event => {setHoleStroke(hole,event.target.value)}} />
                  </TableCell>
                  <TableCell>{holeStrokes[hole] > 0 ? Math.max(0, 2 + parseInt(holePars[hole]) + parseInt(extraHcpStokes(hole)) - parseInt(holeStrokes[hole])) : ""}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{([0,1,2,3,4,5,6,7,8]).reduce((partialSum, hole) => partialSum + (parseInt(holeStrokes[hole]) > 0 ? Math.max(0, 2 + parseInt(holePars[hole]) + extraHcpStokes(hole) - parseInt(holeStrokes[hole])) :0), 0) }</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
        <TabPanel value="3">
        <Table sx={{ minWidth: 100, maxWidth: 360 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
              <TableCell><b>{language === "eng" ? "Hole" : "Hull"}</b></TableCell>
                <TableCell><b>Par</b></TableCell>
                <TableCell><b>Hcp</b></TableCell>
                <TableCell><b>{language === "eng" ? "Strokes" : "Slag"}</b></TableCell>
                <TableCell><b>Score</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {([9,10,11,12,13,14,15,16,17]).map((hole) => (
                <TableRow
                  key={hole}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                  <TableCell><b>{hole+1}</b></TableCell>
                  <TableCell>{holePars[hole]}</TableCell>
                  <TableCell>{extraHcpStokes(hole) > 0 ? "+"+extraHcpStokes(hole) : ""}</TableCell>
                  <TableCell>
                    <TextField sx={{ width: 40 }} size="small" id={"hole-strokes"+hole} value={holeStrokes[hole] > 0 ? holeStrokes[hole] : ""}
                      onChange={event => {setHoleStroke(hole,event.target.value)}} />
                  </TableCell>
                  <TableCell>{holeStrokes[hole] > 0 ? Math.max(0, 2 + parseInt(holePars[hole]) + extraHcpStokes(hole) - parseInt(holeStrokes[hole])) : ""}</TableCell>
                </TableRow>
              ))}
             <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{([9,10,11,12,13,14,15,16,17]).reduce((partialSum, hole) => partialSum + (parseInt(holeStrokes[hole]) > 0 ? Math.max(0, 2 + parseInt(holePars[hole]) + extraHcpStokes(hole) - parseInt(holeStrokes[hole])) : 0), 0)  }</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabPanel>
      </TabContext>
    </div>
  )
}

export default App
